import React, { useState, useContext, useEffect } from "react"
import classnames from "classnames"
import { motion, AnimatePresence } from "framer-motion"
import Fuse from "fuse.js"


/* Import Global Context(s) */
import LocationContext from "~context/currentLocation"

/* Import Global Hooks */
import { useGalleries, useGalleryYears, useGalleriesPage, useWebsiteConfiguration } from "~queries/hooks"

/* Import Global Component(s) */
import LocationNav from '~components/location/location'
import BlockContent from "~components/blockContent/blockContent"
import Footer from "~components/footer/footer"
import Page from "~components/page/page"
import PageHeader from "~components/page/components/pageHeader/pageHeader"
import PageArticle from "~components/page/components/pageArticle/pageArticle"
import PageArticleSection from "~components/page/components/pageArticle/components/pageArticleSection/pageArticleSection"
import ImageGrid from "~components/imageGrid/imageGrid"
import Slideshow from "~components/slideshow/slideshow"
import BlockMatrix from "~components/global/blockMatrix"




/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Component(s) */
import Galleries from "~components/pages/galleries/components/galleries/galleries"
import Search from "~components/pages/galleries/components/search/search"

/* Import Local Style(s) */
import "./galleries.scss"


const FilterGalleries = (galleries = [], f) => {
  return galleries.filter(g => {
    if(f == 'all'){
      return true
    }else if(f == 'nest'){
      return g.isNest 
    }else if(f == 'specialProjects'){
      return g.isSpecialProject 
    }
  })
}


const sortGalleriesAlphabetically = (galleries = [], search = false) => {
  // seperate by all #, then letter groups
  const sortedGalleryGroups = "#abcdefghijklmnopqrstuvwxyz".split("")

  // generate object with letter groups
  const sortedGalleries = sortedGalleryGroups.reduce(
    (accumulator, galleryGroup) => {
      accumulator[galleryGroup] = []
      return accumulator
    },
    {}
  )

  // add galleries to respective gallery groups
  galleries.reduce((sortedGalleries, gallery) => {
    const leadingCharacter = search
      ? gallery.item.name[0].toLowerCase()
      : gallery.name[0].toLowerCase()
    if (isNaN(leadingCharacter) && !'+%$.*&!@#^()?<>,_{}[]|'.includes(leadingCharacter)) {
      sortedGalleries[leadingCharacter].push(search ? gallery.item : gallery)
    } else {
      sortedGalleries["#"].push(search ? gallery.item : gallery)
    }
    return sortedGalleries
  }, sortedGalleries)

  return sortedGalleries
}

const GalleriesPage = ({ location }) => {

  const [cL] = useContext(LocationContext)

  const currentLocation = cL.activeLocation

  const { nestColor, specialProjectsColor } = useWebsiteConfiguration()

  const nestVar = nestColor ? {'--color_override':nestColor.hex } : {}
  const spVar = specialProjectsColor ? {'--color_override':specialProjectsColor.hex } : {}

  const showPastYears = true
  const showGalleryYears = true
  const { slideshow, _rawDescription, activeYear, slideshowHouston, _rawDescriptionHouston, activeYearHouston, galleryBlocks } = useGalleriesPage()
  const [isCurrent, setIsCurrent] = useState(true)
  const { miamiBeachYears, houstonYears } = useGalleryYears()
  const [year, setYear] = useState()

  const yearSelected = (year !== undefined && year !== null && year !== '') ? true : false
  const [isGrid, setIsGrid] = useState(true)
  const [activeIsGrid, activeSetIsGrid] = useState(true)
  const galleries = useGalleries({
    year, 
    fairLocation: currentLocation == 'miamiBeach' ? 'miamiBeach' : 'houston' 
  })
  const [searchTerm, setSearchTerm] = useState()
  const [activeSearchTerm, activeSetSearchTerm] = useState()

  // if an active year has been set, show gallery interface
  const showActiveYear = (activeYear && activeYear.year.length > 0) ? true : false
  const activeGalleries = showActiveYear ? useGalleries(currentLocation == 'houston' ? activeYearHouston : activeYear) : []

  const [ activeFilter, setActiveFilter ] = useState('all')
  const [ pastFilter, setPastFilter ] = useState('all')

  const filteredActiveGalleries = FilterGalleries(activeGalleries, activeFilter)
  const filteredGalleries = FilterGalleries(galleries, pastFilter)

  
  useEffect(() => {
    if(currentLocation == 'miamiBeach'){
      


    }else if(currentLocation == 'houston'){
      
    }

  }, [currentLocation])

  // activeYearHouston


  // annual
  const fuse = new Fuse(filteredGalleries, {
    keys: ["name"],
    threshold: 0.2,
    minMatchCharLength: 1,
  })
  const galleriesMatches = fuse.search(searchTerm ? searchTerm : "")

  const handleChange = event => {
    setSearchTerm(event.target.value)
  }

  const sortedGalleries = sortGalleriesAlphabetically(
    searchTerm ? galleriesMatches : filteredGalleries,
    !!searchTerm
  )


  const activeFuse = new Fuse(filteredActiveGalleries, {
    keys: ["name"],
    threshold: 0.2,
    minMatchCharLength: 1,
  })
  const activeGalleriesMatches = activeFuse.search(activeSearchTerm ? activeSearchTerm : "")


  const activeHandleChange = event => {
    activeSetSearchTerm(event.target.value) 
  }

  const activeSortedGalleries = showActiveYear ? sortGalleriesAlphabetically(
    activeSearchTerm ? activeGalleriesMatches : filteredActiveGalleries,
    !!activeSearchTerm
  ) : []

  

  return (
    <Page title="Galleries" location={location}>
      <PageHeader title="Galleries" location={location} />
      <PageArticle title="About" className="about">
        <PageArticleSection>

          <LocationNav />

          {(
            <div className="galleries-description font-weight--light-body">
              {currentLocation == 'miamiBeach' && <h2 className="mb--1 mt--2 center">Miami Beach</h2>}
              {currentLocation == 'houston' && <h2 className="mb--1 mt--2 center">Houston</h2>}
              {currentLocation == 'miamiBeach' && <BlockContent blocks={_rawDescription} />  }
              {currentLocation == 'houston' && <BlockContent blocks={_rawDescriptionHouston} />  }
            </div>
          )}

          {currentLocation == 'miamiBeach' && slideshow.images.length > 0 ? (
            <div className="desktop-only"><ImageGrid images={slideshow.images} /></div>
          ) : null}

          {currentLocation == 'miamiBeach' && slideshow.images.length > 0 ? (
            <div className="mobile-only"><Slideshow slideshow={slideshow} /></div>
          ) : null}

          {currentLocation == 'houston' && slideshowHouston.images.length > 0 ? (
            <div className="desktop-only"><ImageGrid images={slideshowHouston.images} /></div>
          ) : null}

          {currentLocation == 'houston' && slideshowHouston.images.length > 0 ? (
            <div className="mobile-only"><Slideshow slideshow={slideshowHouston} /></div>
          ) : null}

          <BlockMatrix blocks={galleryBlocks} />

          {showActiveYear ? (
            <div className="active-year">
              <Search handleChange={activeHandleChange} value={activeSearchTerm} />
              <div className="view-mode font-size--small">
                <div className="grid">
                  <div className="col col--1_2 mcol--2_5 morder--2 mcol--end mright">
                    <button
                      className={classnames({ active: activeIsGrid })}
                      onClick={() => { activeSetIsGrid(true) }}
                    >
                      Grid,
                    </button>
                    <button
                      className={classnames({ active: !activeIsGrid })}
                      onClick={() => { activeSetIsGrid(false) }}
                    >
                      List
                    </button>
                  </div>
                  <div className="col col--1_2 mcol--3_5 col--end right morder--1 mcol--margin mleft">
                    <button
                      className={ classnames({active: activeFilter == 'all' }) }
                      onClick={() => { setActiveFilter('all') }}
                    >All,</button>
                    <button
                      className={ classnames(['has_override', {active: activeFilter == 'nest' }]) }
                      onClick={() => { setActiveFilter('nest') }}
                      style={nestVar}
                    >Nest</button>
                    {/*<button
                      className={ classnames(['mleft mwhitespace', 'has_override', {active: activeFilter == 'specialProjects' }]) }
                      onClick={() => { setActiveFilter('specialProjects') }}
                      style={spVar}
                    >Special Projects</button>*/}
                  </div>
                </div>
              </div>
              <Galleries galleries={activeSortedGalleries} isGrid={activeIsGrid} />
            </div>
          ) : null }

          <AnimatePresence exitBeforeEnter>
            {(
              <motion.ul
                className="years font-size--small"
                key="years"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
              >
                {currentLocation == 'houston' &&  showGalleryYears && houstonYears.length > 0 && <li className="font-size--small">Houston Past Years: </li>}
                
                {currentLocation == 'houston' &&  showGalleryYears && houstonYears.map((galleryYear, i) => {
                  return (!showActiveYear 
                    || 
                    (showActiveYear 
                      && (galleryYear != activeYear.year || (galleryYear == activeYear.year && activeYear.fairLocation != 'houston'))
                      )
                    ) ? (
                    <li key={galleryYear} className="font-size--small">
                      <button
                        className={classnames({ active: year === galleryYear }) + ' font-size--small'}
                        onClick={() => {                          
                          if(galleryYear === year){
                            setYear()
                          }else{
                            
                            setYear(galleryYear)
                          }
                        }}
                      >
                        {galleryYear}
                      </button>
                    </li>
                  ) : null
                })}
              </motion.ul>
            )}
          </AnimatePresence>

          <AnimatePresence exitBeforeEnter>
            {(
              <motion.ul
                className="years font-size--small"
                key="years"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={elementTransitionAnimation.variants}
              >
                {currentLocation == 'miamiBeach' && showGalleryYears && miamiBeachYears.length > 0 && <li className="font-size--small">Miami Beach Past Years: </li>}
                
                {currentLocation == 'miamiBeach' && showGalleryYears && miamiBeachYears.map((galleryYear, i) => {
                  return (
                    !showActiveYear 
                    || (showActiveYear 
                      && (galleryYear != activeYear.year 
                        || (galleryYear == activeYear.year && activeYear.fairLocation == 'houston' )
                        )
                      )
                    ) ? (
                    <li key={galleryYear} className="font-size--small">
                      <button
                        className={classnames({ active: year === galleryYear }) + ' font-size--small'}
                        onClick={() => {
                          if(galleryYear === year){
                            setYear()
                          }else{
                            setYear(galleryYear)
                          }
                        }}
                      >
                        {galleryYear}
                      </button>
                    </li>
                  ) : null
                })}
              </motion.ul>
            )}
          </AnimatePresence>

          { yearSelected ? (
              <div>
                <Search handleChange={handleChange} value={searchTerm} />
                <div className="view-mode font-size--small">
                  
                  <div className="grid">
                    <div className="col col--1_2 mcol--2_5 mright morder--2 mcol--end">
                      <button
                        className={classnames({ active: isGrid })}
                        onClick={() => { setIsGrid(true) }}
                      >
                        Grid,
                      </button>
                      <button
                        className={classnames({ active: !isGrid })}
                        onClick={() => { setIsGrid(false) }}
                      >
                        List
                      </button>
                    </div>
                    <div className="col col--1_2 mcol--3_5 col--end right mleft morder--1 mcol--margin">
                      <button
                        className={ classnames({active: pastFilter == 'all'})}
                        onClick={() => { setPastFilter('all') }}
                      >All,</button>
                      <button
                        className={ classnames(['has_override', {active: pastFilter == 'nest'}])}
                        onClick={() => { setPastFilter('nest') }}
                        style={nestVar}
                      >Nest</button>
                      {/*<button
                        className={ classnames(['has_override mleft mwhitespace',{active: pastFilter == 'specialProjects'}])}
                        onClick={() => { setPastFilter('specialProjects') }}
                        style={spVar}
                      >Special Projects</button>*/}
                    </div>
                  </div>
                </div>
                <Galleries isPast={true} galleries={sortedGalleries} isGrid={isGrid} />
              </div>
            ) : null }
        </PageArticleSection>
      </PageArticle>
      <Footer />
    </Page>
  )
}

export default GalleriesPage
